.ticket-listing {
  .list-page {
    padding-top: 8vh;

    // padding-left: 15vw;
    // padding-right: 15vw;
    // @include media-breakpoint-down(md) {
    //   padding-left: 8vw;
    //   padding-right: 8vw;
    // }
  }
}
